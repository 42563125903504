import React, { PureComponent } from 'react';
import classNames from 'classnames';

class Checkbox extends PureComponent {
   constructor(props) {
      super(props);
      this.state = {
         hovering: false,
      }
   }

   setHovering = () => {
      if (this.props.colorActive && !this.state.hovering) {
         this.setState({
            hovering: true,
         });
      }
   }

   removeHovering = () => {
      if (this.props.colorActive && this.state.hovering) {
         this.setState({
            hovering: false,
         });
      }
   }

   render() {
      const { label, value, isChecked, colorActive, readOnly, disabled } = this.props;
      return (
         <label className={classNames('checkbox-wrapper', disabled ? 'disabled' : '')}
            onMouseOver={this.setHovering}
            onMouseLeave={this.removeHovering}
         >
            <span className={`cds-checkbox ${isChecked ? 'checked' : ''}`}>
               <input type="checkbox" value={value} checked={isChecked} onChange={this.props.onChange} disabled={readOnly || disabled} tabIndex="-1" />
               <span className="cds-checkbox-inner" tabIndex="0"
                  style={(isChecked && colorActive) ? { borderColor: colorActive, background: colorActive } :
                     (colorActive && this.state.hovering ? { borderColor: colorActive } : {})}
               >
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M1.5 6L4.5 9L10.5 3" stroke="white" />
                  </svg>
               </span>
            </span>
            {
               label &&
               <span className="checkbox-label">{label || ''}</span>
            }
         </label>
      );
   }
}

export default Checkbox;