import React, { PureComponent } from 'react';

class IconSearch extends PureComponent {
   render() {
      return (
         <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <circle cx="10.999" cy={11} r="9.25" stroke="currentColor" strokeWidth="1.5" />
            <path
               d="M18 18.001L22 22.001"
               stroke="currentColor"
               strokeWidth="1.5"
               strokeLinecap="round"
            />
         </svg>
      );
   }
}

export default IconSearch;