import React, { memo, useCallback, useMemo } from 'react';
import { Row, Col } from 'antd';
import ProductItem from '../product-item/ProductItem';
import { functionUtils } from '@utils/function';
import { FileSize } from '@utils/enums/FileSize';
import ProductItemSkeleton from '@skeleton/skeleton-components/ProductItemSkeleton';

const ProductList = memo(({ data, loading }) => {
   const dataList = useMemo(() => {
      return data.map((item) => {
         let imgUrl = '';
         if (item.attachments?.length) {
            imgUrl = item.attachments[0]?.formats?.[FileSize.MEDIUM] || '';
         }
         return {
            uid: item.uid,
            slug: item.slug,
            name: item.name,
            price: item.price,
            localPrice: item.local_price,
            rating: item.rate,
            reviews: item.reviews,
            imgUrl,
            status: functionUtils.getProductDisplayStatus(item),
         };
      });
   }, [data]);

   const renderSkeleton = () => {
      return (
         <>
            {Array.from({ length: 3 }, (_, idx) => (
               <Col lg={8} md={8} sm={24} xs={24} key={idx}>
                  <ProductItemSkeleton />
               </Col>
            ))}
         </>
      );
   };

   return (
      <div className="product-list">
         <Row gutter={[30, 30]}>
            {loading
               ? renderSkeleton()
               : dataList.map((item) => {
                    return (
                       <Col lg={8} md={8} sm={24} xs={24} key={item.uid}>
                          <ProductItem
                             slug={item.slug}
                             imgUrl={item.imgUrl}
                             name={item.name}
                             price={item.price}
                             localPrice={item.localPrice}
                             rating={item.rating}
                             reviews={item.reviews}
                             status={item.status}
                          />
                       </Col>
                    );
                 })}
         </Row>
      </div>
   );
});

export default ProductList;
