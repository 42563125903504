import Breakpoint from '@components/Breakpoint/Breakpoint';
import IconFilter24 from '@icons/IconFilter24';
import React, { PureComponent } from 'react';
import IconCancel from '../../components/Icons/IconCancel';
import IconSearch from '../../components/Icons/IconSearch';

class Searchbox extends PureComponent {
   state = {
      value: '',
      focused: false,
   };

   setInputRef = (r) => {
      this.inputRef = r;
   };

   onChange = (e) => {
      let value = e.target.value;
      this.setState({
         value,
      });
   };

   clearSearch = () => {
      this.setState({
         value: '',
      });
      this.props.onSearch && this.props.onSearch('');
   };

   setFocused = () => {
      this.setState({
         focused: true,
      });
   };

   removeFocused = () => {
      this.setState({
         focused: false,
      });
   };

   handleKeyDown = (e) => {
      if (e.keyCode === 13) {
         // enter
         this.inputRef.blur();
         this.props.onSearch && this.props.onSearch(this.state.value);
      }
   };

   render() {
      const { toggleFilter } = this.props;
      return (
         <div className={`search-box-container ${this.state.focused ? 'focused' : ''}`}>
            <span className="search-ic">
               <IconSearch />
            </span>
            <input
               ref={this.setInputRef}
               value={this.state.value}
               placeholder="Search"
               onFocus={this.setFocused}
               onBlur={this.removeFocused}
               onChange={this.onChange}
               onKeyDown={this.handleKeyDown}
            />
            <div
               className={`cancel-search ${this.state.value.trim() ? '' : 'hidden'}`}
               onClick={this.clearSearch}
            >
               <IconCancel />
            </div>
            <Breakpoint md down>
               <div className="filter" onClick={toggleFilter}>
                  <IconFilter24 />
               </div>
            </Breakpoint>
         </div>
      );
   }
}

export default Searchbox;
