import React from 'react';

const IconFilter24 = (props) => (
   <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path d="M2 5H22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
      <path
         d="M5.5 12H18.5"
         stroke="currentColor"
         strokeWidth="1.5"
         strokeLinecap="round"
      />
      <path d="M9 19H15" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
   </svg>
);

export default IconFilter24;
