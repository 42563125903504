import Breakpoint from '@components/Breakpoint/Breakpoint';
import IconArrowDown from '@icons/IconArrowDown';
import IconCancel from '@icons/IconCancel';
import IconCheck from '@icons/IconCheck';
import MbBackdrop from '@views/default-layout/header/MbBackdrop';
import { Dropdown, Menu } from 'antd';
import React, { memo, useMemo, useState } from 'react';

const sortList = [
   {
      label: 'Newest',
      name: 'created_at',
      sortType: 'DESC',
   },
   {
      label: 'Name (A - Z)',
      name: 'name',
      sortType: 'ASC',
   },
   {
      label: 'Name (Z - A)',
      name: 'name',
      sortType: 'DESC',
   },
   {
      label: 'Price (Low - High)',
      name: 'price',
      sortType: 'ASC',
   },
   {
      label: 'Price (High - Low)',
      name: 'price',
      sortType: 'DESC',
   },
   {
      label: 'Rating (Low - High)',
      name: 'rate',
      sortType: 'ASC',
   },
   {
      label: 'Rating (High - Low)',
      name: 'rate',
      sortType: 'DESC',
   },
];

const Filter = memo(({ orderBy, sortType, changeOrderBy }) => {
   const [visible, setVisible] = useState(false);

   const currentOrderByName =
      sortList.find((i) => i.name === orderBy && i.sortType === sortType)?.label || '';

   const filterMenu = useMemo(() => {
      const onClick = (name, sortType) => {
         changeOrderBy(name, sortType);
         setVisible(false);
      };
      return (
         <Menu className="common-menu products-filter-menu">
            <Breakpoint md down>
               <Menu.Item className="products-filter-menu-close" key="close">
                  <Breakpoint md down>
                     <label className="title">Sort by</label>
                  </Breakpoint>
                  <i onClick={() => setVisible(false)}>
                     <IconCancel />
                  </i>
               </Menu.Item>
            </Breakpoint>
            {sortList.map((sortItem) => {
               const isActive =
                  sortItem.name === orderBy && sortItem.sortType === sortType;
               return (
                  <Menu.Item
                     className={`common-menu-item ${isActive ? 'active' : ''}`}
                     key={`${sortItem.name}_${sortItem.sortType}`}
                     onClick={() => onClick(sortItem.name, sortItem.sortType)}
                  >
                     <div>{sortItem.label}</div>
                     {isActive && <IconCheck />}
                  </Menu.Item>
               );
            })}
         </Menu>
      );
   }, [changeOrderBy, orderBy, sortType]);

   return (
      <>
         <Dropdown
            overlayClassName="common-dropdown products-filter-dropdown"
            overlay={filterMenu}
            placement="bottomRight"
            trigger="click"
            visible={visible}
            onVisibleChange={(v) => setVisible(v)}
         >
            <div className="filter-container">
               {currentOrderByName ? (
                  <>
                     Sort by
                     <span className="sorted-by">{currentOrderByName}</span>
                  </>
               ) : (
                  'Select sort type'
               )}
               <IconArrowDown />
            </div>
         </Dropdown>
         <MbBackdrop visible={visible} onClick={() => setVisible(false)} />
      </>
   );
});

export default Filter;
