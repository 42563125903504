import React, { PureComponent } from 'react';

class IconArrowDown extends PureComponent {
   render() {
      return (
         <svg
            width={12}
            height={12}
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
         >
            <path
               d="M1 4L6 9L11 4"
               stroke="currentColor"
               strokeWidth="1.5"
               strokeLinejoin="round"
            />
         </svg>
      );
   }
}

export default IconArrowDown;