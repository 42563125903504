import React, { useState, memo, useRef, useCallback, useEffect, useMemo } from 'react';
import SEO from '../../components/Common/SEO';
import { Row, Col, Dropdown, Menu } from 'antd';
import IconArrowDown from '../../components/Icons/IconArrowDown';
import Footer from '../../views/default-layout/footer/Footer';
import ProductList from '../../views/product-list/ProductList';
import Searchbox from '../../views/product-list/Searchbox';
import { createCancelToken } from '@apis/ServiceController';
import RequestModel from '@apis/models/RequestModel';
import { productApi } from '@apis/productApi';
import Pagination from '@components/Pagination';
import FilterCategory from '@views/product-list/FilterCategory';
import IconCheck from '@icons/IconCheck';
import ContentLoading from '@views_admin/ContentLoading';
import IconCancel12 from '@icons/IconCancel12';
import { functionUtils } from '@utils/function';
import Breakpoint from '@components/Breakpoint/Breakpoint';
import IconCancel from '@icons/IconCancel';
import Filter from '@views/product-list/Filter';

///
const Products = memo(() => {
   const [searchText, setSearchText] = useState('');
   const [filterOpen, setFilterOpen] = useState(false);
   const [filter, setFilter] = useState({
      categories: [],
      page: 1,
      pageSize: 24,
      orderBy: 'created_at',
      sortType: 'DESC',
   });
   const [data, setData] = useState({
      content: [],
      totalRows: 0,
      totalPages: 1,
   });
   const [loading, setLoading] = useState(true);
   const [checkedCategories, setCheckedCategories] = useState({});
   const cancelToken = useRef();

   useEffect(() => {
      if (filterOpen) {
         functionUtils.setModalOpen(true);
      } else {
         functionUtils.setModalOpen(false);
      }

      return () => {
         functionUtils.setModalOpen(false);
      };
   }, [filterOpen]);

   useEffect(() => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
      });
   }, []);

   useEffect(() => {
      if (cancelToken.current) {
         cancelToken.current.cancel();
      }
      setLoading(true);
      cancelToken.current = createCancelToken();
      const loadProducts = async () => {
         const requestBody = new RequestModel([
            filter,
            {
               search: searchText.trim(),
            },
         ]);
         const responseData = await productApi.getProductsMatrix(
            requestBody,
            cancelToken.current?.token
         );
         console.log('responseData', responseData);
         if (responseData.isSuccess) {
            const { content, totalRows, totalPages } = responseData.data;
            setData((prevData) => ({
               ...prevData,
               content: content || [],
               totalRows: totalRows,
               totalPages: totalPages,
            }));
         }
         setSearchText(searchText.trim());
         setLoading(false);
      };
      loadProducts();

      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [filter, searchText]);

   const handleSearch = useCallback((value) => {
      setSearchText(value);
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: 1,
      }));
   }, []);

   const onPaginationChange = useCallback(
      (page, pageSize) => {
         window.scrollTo({
            top: 0,
            behavior: 'smooth',
         });
         setFilter({
            ...filter,
            page: page || filter.page,
            pageSize: pageSize || filter.pageSize,
         });
      },
      [filter]
   );

   const handleFilterCategory = useCallback(
      ({ uid, name }) => {
         let currentSelectedCategories = filter.categories;
         if (currentSelectedCategories.some((i) => i === uid)) {
            currentSelectedCategories = currentSelectedCategories.filter(
               (i) => i !== uid
            );
         } else {
            currentSelectedCategories = [...currentSelectedCategories, uid];
         }
         setFilter({
            ...filter,
            categories: currentSelectedCategories,
            page: 1,
         });
         setCheckedCategories((prevData) => ({
            ...prevData,
            [uid]: {
               uid,
               name,
            },
         }));
      },
      [filter]
   );

   const clearFilter = () => {
      setFilter({
         ...filter,
         categories: [],
         page: 1,
      });
   };

   const removeFilterItem = useCallback(
      (itemUid) => {
         let currentSelectedCategories = filter.categories.filter((i) => i !== itemUid);
         setFilter({
            ...filter,
            categories: currentSelectedCategories,
            page: 1,
         });
      },
      [filter]
   );

   const changeOrderBy = useCallback((orderBy, sortType) => {
      setFilter((v) => ({
         ...v,
         orderBy,
         sortType,
         page: 1,
      }));
   }, []);

   const hasCategoryFilter = filter.categories.length !== 0;
   const hasFilter = hasCategoryFilter || searchText.trim();

   const filterredList = useMemo(() => {
      if (filter.categories?.length) {
         const results = filter.categories
            .map((i) => checkedCategories[i] || null)
            .filter((v) => v);
         return results;
      }
      return [];
   }, [filter.categories, checkedCategories]);

   console.log(filterredList);

   const toggleFilter = useCallback(() => {
      setFilterOpen((v) => !v);
   }, []);

   const renderFilteredTags = useCallback(() => {
      return (
         filterredList.length > 0 && (
            <div className="current-filter-tags">
               {filterredList.map((filterItem) => {
                  return (
                     <div className="filter-item" key={filterItem.uid}>
                        <span>{filterItem.name}</span>
                        <i onClick={() => removeFilterItem(filterItem.uid)}>
                           <IconCancel12 />
                        </i>
                     </div>
                  );
               })}
            </div>
         )
      );
   }, [filterredList, removeFilterItem]);

   return (
      <>
         <SEO title={'Products'} />
         <div className="products-page">
            <Row gutter={30}>
               <Col lg={6} md={24} sm={24} xs={24}>
                  <div className={`products-filter ${filterOpen ? 'open' : ''}`}>
                     <div className="filter-header">
                        <Breakpoint md down>
                           <div className="cancel" onClick={toggleFilter}>
                              <IconCancel />
                           </div>
                        </Breakpoint>
                        <span className="title">Filter</span>
                        {hasCategoryFilter && (
                           <span className="clear" onClick={clearFilter}>
                              Clear
                           </span>
                        )}
                     </div>
                     <div className="filter-list">
                        <div className="filter-section">
                           <label className="title">Category</label>
                           <FilterCategory
                              values={filter.categories}
                              onChange={handleFilterCategory}
                           />
                        </div>
                     </div>
                  </div>
               </Col>
               <Col lg={18} md={24} sm={24} xs={24}>
                  <div className="products-content">
                     <Searchbox onSearch={handleSearch} toggleFilter={toggleFilter} />
                     <div className="results-and-filters">
                        <div className="left-content">
                           {searchText.trim() && (
                              <span className="search-desc">
                                 <span className="number">{data.totalRows}</span>Results
                                 for
                                 <span className="search-text">
                                    “{searchText.trim()}”
                                 </span>
                              </span>
                           )}
                           <Breakpoint lg up>
                              {renderFilteredTags()}
                           </Breakpoint>
                        </div>
                        <Filter
                           orderBy={filter.orderBy}
                           sortType={filter.sortType}
                           changeOrderBy={changeOrderBy}
                        />
                     </div>
                     <Breakpoint md down>
                        <div className="filtered-tags">{renderFilteredTags()}</div>
                     </Breakpoint>

                     {!loading && (
                        <>
                           {data.content.length === 0 && (
                              <>
                                 {hasFilter ? (
                                    <div className="no-product-content">
                                       <label>No products found</label>
                                       <span>Try another keyword or filter</span>
                                    </div>
                                 ) : (
                                    <div className="no-product-content">
                                       <label>No products</label>
                                       <span>Hmm. We will update later!</span>
                                    </div>
                                 )}
                              </>
                           )}
                        </>
                     )}
                     <ProductList data={data.content} loading={loading} />
                     {data.totalPages > 1 && (
                        <Pagination
                           page={filter.page}
                           pageSize={filter.pageSize}
                           total={data.totalRows}
                           onChange={onPaginationChange}
                        />
                     )}
                  </div>
               </Col>
            </Row>
         </div>
         <Footer />
      </>
   );
});

export default Products;
